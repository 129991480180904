import PropTypes from 'prop-types';

export const message = PropTypes.shape({
  id: PropTypes.string.isRequired,
  notificationText: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate:PropTypes.string.isRequired,

  //default fields
  updatedAt:PropTypes.string.isRequired,
  createdAt:PropTypes.string.isRequired
});