import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  ButtonToolbar,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Row,
} from 'react-bootstrap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import genericMessages from '../../../../../../app/lib/genericMessages';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import { vState } from '../../../../../../app/lib/utils';

const messages = defineMessages({
  title: {
    id: 'projects.arriva.app.modules.SoftwarePackage.components.NotificationForm.title',
    description: 'Notification message titel.',
    defaultMessage: 'Title',
  },
});

const NotificationForm = ({
  handleChange,
  handleSubmit,
  errors,
  isSubmitting,
  onClickDelete,
  setFieldValue,
  values,
}) => {
  const handleSendNotification = async () => {
    await setFieldValue('sendNotification', true);
    handleSubmit();
  };

  return (
    <Form horizontal onSubmit={handleSubmit}>
      <Row>
        <Col xs={12} md={8} lg={8}>
          <FormGroup controlId="title" validationState={vState(errors, 'title')}>
            <ControlLabel>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...messages.title} />
            </ControlLabel>

            <FormControl type="text" autoComplete="off" value={values.title} onChange={handleChange} />

            {errors.title && <HelpBlock>{errors.title}</HelpBlock>}
          </FormGroup>

          <FormGroup controlId="tag" validationState={vState(errors, 'tag')}>
            <ControlLabel>Tag</ControlLabel>

            <FormControl type="text" autoComplete="off" value={values.tag} onChange={handleChange} />

            <HelpBlock style={{ fontStyle: 'italic', fontSize: 12 }}>
              E.g. format #tag1 #tag2
            </HelpBlock>

            {errors.tag && <HelpBlock>{errors.tag}</HelpBlock>}
          </FormGroup>

          <hr />

          <FormGroup controlId="formActions">
            <ButtonToolbar>
              {values.id && (
              <Button bsStyle="danger" onClick={() => onClickDelete()}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <FormattedMessage {...genericMessages.delete} />
              </Button>
              )}

              <Button
                bsStyle="primary"
                onClick={() => handleSendNotification()}
                disabled={isSubmitting || Object.keys(errors).length > 0}
              >
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <FormattedMessage {...genericMessages.saveAndSend} />
              </Button>

              <Button type="submit" disabled={isSubmitting || Object.keys(errors).length > 0}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <FormattedMessage {...genericMessages.save} />
              </Button>
            </ButtonToolbar>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
NotificationForm.propTypes = {
  // Own props
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClickDelete: PropTypes.func.isRequired,

  errors: PropTypes.shape({
    title: PropTypes.string,
    tag: PropTypes.string,
  }),

  values: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    tag: PropTypes.string,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
  locale: PropTypes.string.isRequired,
};

// noinspection JSCheckFunctionSignatures
export default injectIntl(NotificationForm);
