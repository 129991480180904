import {
  NOTIFICATIONS_LOADING_MESSAGES,
  NOTIFICATIONS_LOADING_MESSAGES_DONE,
  NOTIFICATIONS_SET_MESSAGES
} from './actions';
import { registerWithRootReducer } from '../../../../../app/modules/reducers/projects';

const ACTION_HANDLERS = {
  [NOTIFICATIONS_SET_MESSAGES]: (state, action) => ({ ...state, messages: action.payload }),
  [NOTIFICATIONS_LOADING_MESSAGES]: (state) => ({ ...state, loadingMessages: true }),
  [NOTIFICATIONS_LOADING_MESSAGES_DONE]: (state) => ({ ...state, loadingMessages: false }),
};

const initialState = {
  messages: [],
  loadingMessages: false,
};

registerWithRootReducer('notifications', (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
});
