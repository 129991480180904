import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { defineMessages, injectIntl } from 'react-intl';
import { flashErrorMessage } from 'redux-flash';
import constants from '../../../lib/drive-constants';
import {
  get, patch, post, remove,
} from '../../../lib/driveApi';

import { setNotifications } from '../actions';
import { selectIdentifier, getNotificationByIdentifier } from '../selectors';
import { notification as notificationShape } from '../types';

import { getLocale } from '../../../../../../app/modules/selectors/locale';
import genericMessages from '../../../../../../app/lib/genericMessages';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import NotificationForm from './NotificationForm';

const messages = defineMessages({
  notification: {
    id: 'projects.arriva.app.modules.SoftwarePackage.components.NotificationDetail.notification',
    description: 'Notification detail titel.',
    defaultMessage: 'Notification',
  },
});

class NotificationDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      showDeleteModal: false,
      showConfirmationModal: false,
      notificationCopy: {},
    };
  }

  componentDidMount() {
    const {
      notification, notificationId, get, setNotifications,
    } = this.props;

    // Check if the notification is false, if so, there are no notifications at all
    // and the user probably got here by using the direct url
    // So, load the notification
    if (notification === false && notificationId) {
      get({ path: `software-package/message/${notificationId}` })
        .then((notification) => {
          setNotifications([notification]);
        })
        .catch((err) => this.setState({ error: err }));
    }
  }

  renderForm() {
    const {
      notification, flashErrorMessage, history, locale, patch, post,
    } = this.props;

    const initialValues = {
      ...notification,
      sendNotification: false,
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          let { tag } = values;

          if (!Array.isArray(tag)) {
            tag = Array.from(values.tag.split(/[ ,]+/));
          }

          const body = {
            ...omit(['createdAt', 'updatedAt', 'tag', 'sendNotification'], values),
            tag,
          };

          (notification.id ? patch : post)({ path: `software-package/message/${notification.id ? notification.id : ''}`, body })
            .then((result) => {
              if (result.message === constants.MSG_SUCCESS) {
                setSubmitting(false);

                if (values.sendNotification) {
                  if (!notification.id) {
                    body.id = result.id;
                  };

                  this.setState({ notificationCopy: body, showConfirmationModal: true });
                } else {
                  history.replace('/message');
                }
                return;
              }

              Sentry.captureException(new Error(`Something went wrong: softwarePackageForm post/patch-> ${result}`));
              this.setState({ error: `Er ging iets fout bij het opslaan: ${result}` });
            })
            .catch((error) => {
              console.error(error);
              Sentry.captureException(error);
              flashErrorMessage(error.message);

              setSubmitting(false);
            });
        }}
        validate={(values) => {
          const errors = {};

          const required = ['title', 'tag'];

          // Make sure the required fields are there
          required.forEach((k) => {
            if (!values[k]) errors[k] = 'Verplicht';
          });

          return errors;
        }}
      >
        {(props) => (
          /* eslint-disable react/jsx-props-no-spreading */
          <NotificationForm
            {...props}
            locale={locale}
            onClickDelete={() => this.setState({ showDeleteModal: true })}
          />
        )}
      </Formik>
    );
  }

  renderConfirmationModal() {
    const { history, patch } = this.props;
    const { showConfirmationModal, notificationCopy } = this.state;

    const close = () => {
      this.setState({ showConfirmationModal: false });
      history.push('/message');
    };

    const sendNotification = (pushNotification) => {
      const body = {
        title: notificationCopy.title,
        tag: notificationCopy.tag,
        pushNotification,
        sendNotification: true,
      };

      patch({ path: `software-package/message/${notificationCopy.id}`, body })
        .then(() => history.push('/message'))
        .catch((err) => {
          this.setState({ error: err });

          Sentry.captureException(
            new Error(`Something went wrong: /software-package/message/${notificationCopy.id} PATCH -> ${err}`),
          );
        });
    };

    return (
      <Modal show={showConfirmationModal} onHide={close}>
        <Modal.Header>
          <Modal.Title>Melding verzenden</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p style={{ padding: 15 }}>
            {'Wil je ook een push notificatie sturen voor dit bericht?'}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={close}>Sluiten</Button>
          <Button bsStyle="primary" onClick={() => sendNotification(false)}>Verstuur</Button>
          <Button bsStyle="success" onClick={() => sendNotification(true)}>Verstuur & push notificatie</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderDeleteModal() {
    const { notification, history, remove } = this.props;
    const { showDeleteModal } = this.state;

    const close = () => this.setState({ showDeleteModal: false });

    const removeNotification = () => {
      remove({ path: `software-package/message/${notification.id}` })
        .catch((err) => {
          this.setState({ error: err });

          Sentry.captureException(
            new Error(`Something went wrong: /software-package/message/${notification.id} DELETE -> ${err}`),
          );
        })
        .then(() => history.push('/message'));
    };

    return (
      <Modal show={showDeleteModal} onHide={close}>
        <Modal.Header>
          <Modal.Title>Verwijderen bericht</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p style={{ padding: 15 }}>
            {`Weet u zeker dat u het pakket: '${notification.title}' wilt verwijderen?`}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={close}>Sluiten</Button>
          <Button bsStyle="danger" onClick={() => removeNotification()}>Verwijderen</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const { notification, notificationId, intl: { formatMessage } } = this.props;
    const { error } = this.state;

    if (error) {
      // eslint-disable-next-line react/jsx-one-expression-per-line
      return <strong>Er ging iets fout! ({error})</strong>;
    }

    if (!notification && notificationId) {
      return <strong>Kon het pakket niet vinden!!</strong>;
    }

    return (
      <div className="well">
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        <h2>
          {formatMessage(messages.notification)}
          {' '}
          {notification
            ? formatMessage(genericMessages.edit).toLowerCase()
            : formatMessage(genericMessages.add).toLowerCase()}
        </h2>

        {this.renderForm()}
        {this.renderDeleteModal()}
        {this.renderConfirmationModal()}
      </div>
    );
  }
}

NotificationDetail.propTypes = {
  // Own props
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),

  // react-intl props
  intl: IntlShape.isRequired,

  // Dispatch props
  get: PropTypes.func.isRequired,
  patch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  setNotifications: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,

  // State props
  notification: PropTypes.oneOfType([
    notificationShape,
    PropTypes.bool,
  ]),
  notificationId: PropTypes.string,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => ({
  notification: getNotificationByIdentifier(state, props),
  notificationId: selectIdentifier(state, props),
  locale: getLocale(state),
});

const mapDispatchToProps = {
  get,
  patch,
  post,
  remove,
  setNotifications,
  flashErrorMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NotificationDetail));
